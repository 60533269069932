import { UrlObject } from 'url'

import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

import { Link } from '@/ui/link/link'

type RedirectProps = {
  redirectTo: UrlObject
}

/**
 * 指定したページにリダイレクトする
 */
export const Redirect: FC<RedirectProps> = ({ redirectTo }) => {
  const router = useRouter()

  useEffect(() => {
    router.replace(redirectTo)
  }, [router, redirectTo])

  return (
    <Link href={redirectTo}>{`${window.origin}${redirectTo.pathname} に移動しています...`}</Link>
  )
}
